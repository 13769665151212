* {
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #2c3e50;
  border-radius: 20px; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #578ebe; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

@keyframes smoothBounce {
  0% {
    transform: translateY(0); /* Posição inicial */
  }
  50% {
    transform: translateY(-10px); /* Sobe um pouco */
  }
  100% {
    transform: translateY(0); /* Retorna para a posição inicial */
  }
}
