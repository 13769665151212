@keyframes slideLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(
      -8000px
    ); /* Desloca o conteúdo para a largura total do contêiner */
  }
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-8000px); /* Desloca o conteúdo para a direita */
  }
}

@keyframes slideLeftSmall {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(
      -2500px
    ); /* Para telas pequenas, ajustado proporcionalmente */
  }
}

@keyframes slideLeftMedium {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(
      -4800px
    ); /* Para telas pequenas, ajustado proporcionalmente */
  }
}

.logos2 {
  overflow: hidden;
  padding: 10px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos2:before,
.logos2:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos2:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 90%, white);
}

.logos2:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 90%, white);
}

.logos2:hover .logos2-slide-wrapper {
  animation-play-state: paused;
}

.logos2-slide-wrapper {
  display: flex;
  gap: 5px;
  animation: slideLeft 60s linear infinite alternate; /* Movimento alternado para esquerda e direita */
}

.logos2-slide {
  display: flex;
  gap: 5px;
}

.logos2-slide div {
  flex-shrink: 0;
}

@media (max-width: 600px) {
  .logos2-slide-wrapper {
    animation: slideLeftSmall 30s linear infinite alternate; /* Animação ajustada para telas pequenas */
  }

  .logos2-slide div {
    width: 120px; /* Ajuste o tamanho dos itens no celular */
  }
}

@media (max-width: 1000px) {
  .logos2-slide-wrapper {
    animation: slideLeftMedium 30s linear infinite alternate; /* Animação ajustada para telas pequenas */
  }

  .logos2-slide div {
    width: 120px; /* Ajuste o tamanho dos itens no celular */
  }
}
